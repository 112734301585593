import React from 'react';
import Layout from '../components/layout';

function Page404() {
  return (
    <Layout>
      <div className="columns is-centered">
        <div className="column">
          <h1 className="title">Fejl</h1>
          <div className="content">Siden findes ikke.</div>
        </div>
      </div>
    </Layout>
  );
}

export default Page404;
